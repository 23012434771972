import Footer from "./layout/footer/Footer"
import Header from "./layout/header/Header"
import Main from "./layout/main/Main"

function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  )
}

export default App